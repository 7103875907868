import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import TabItem from './features/tab-item'
import TabContent from './features/tab-content'
import ArrowRight2Icon from '../assets/images/icons/arrow-right-2.svg'

import isInViewport from '../utils/is-in-viewport'

const Features = () => {
  const [visible, setVisible] = useState(false)
  const [active, setActive] = useState('trust-tab')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    const featuresSectionTabs = document.getElementById('features-section-tabs')
    visible ? featuresSectionTabs.classList.add('sticky') : featuresSectionTabs.classList.remove('sticky')
  }, [visible])

  const handleScroll = () => {
    // TODO: refactor
    const features = document.getElementById('features-section')
    const featuresSectionTabs = document.getElementById('features-section-tabs')
    const bottom = features.getBoundingClientRect().bottom
    const headerHeight = document.getElementsByTagName('header')[0].clientHeight

    if (bottom - featuresSectionTabs.clientHeight > headerHeight) {
      featuresSectionTabs.classList.add('z-50')
    } else {
      featuresSectionTabs.classList.remove('z-50')
    }
    if (isInViewport(features)) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  return (
    <div id="features-section">
      <div id="features-section-tabs" className="container hidden lg:block mx-auto lg:top-[140px]">
        <div className="bg-white flex items-center px-[136px] shadow-xs rounded-b-lg grid gap-5 grid-cols-4 h-[116px] max-w-[1280px] mx-auto font-GTWalsheimPro">
          <TabItem tabContentId='trust-tab-top'>
            <div className={ `${active === 'trust-tab' ? 'bg-leg-light-red text-main-black' : 'bg-white text-dark-gray'} text-center rounded-[52px] py-1 hover:shadow-xs`}>Trust</div>
          </TabItem>
          <TabItem tabContentId='presence-tab-top'>
            <div className={`${active === 'presence-tab' ? 'bg-leg-light-red text-main-black' : 'bg-white text-dark-gray'} text-center rounded-[52px] py-1 hover:shadow-xs`}>Presence</div>
          </TabItem>
          <TabItem tabContentId='communication-tab-top'>
            <div className={`${active === 'communication-tab' ? 'bg-leg-light-red text-main-black' : 'bg-white text-dark-gray'} text-center  rounded-[52px] py-1 hover:shadow-xs`}>Communication</div>
          </TabItem>
          <TabItem tabContentId='transparency-tab-top'>
            <div className={`${active === 'transparency-tab' ? 'bg-leg-light-red text-main-black' : 'bg-white text-dark-gray'} text-center rounded-[52px] py-1 hover:shadow-xs`}>Transparency</div>
          </TabItem>
        </div>
      </div>
      <TabContent
        tabId='trust-tab'
        setActiveTabId='trust-tab-set-active'
        setActive={setActive}
      >
        <div id='trust-tab' className="container flex flex-col lg:flex-row m-auto py-10 px-6 lg:px-16 lg:py-12">
          <div className="flex items-center justify-center flex-1 p-0 lg:p-10">
            <video className="video" autoPlay loop muted playsInline>
              <source src={'/videos/trust-loop.m4v'} type="video/mp4" />
            </video>
          </div>
          <div className="flex flex-col lg:w-[55%] lg:max-w-[628px] justify-center px-0 pt-12 md:py-14 md:px-11">
            <div id="trust-tab-set-active" className="invisible h-0 relative top-[50%]"></div>
            <div id="trust-tab-top" className="flex items-center">
              <span className="text-xl font-semibold text-dark-gray font-inter">TRUST</span>
            </div>
            <h2 className="font-GTWalsheimPro font-medium text-3xl my-4">
              Better the relationship you know
            </h2>
            <div className="font-GTWalsheimPro text-alt-text text-sm leading-7 mb-8">
              <p>
                Empower your law firms to serve you better to help sustain trust and positive working relations.
                Understand which firm does what and who stars in key specialist areas.
                With LegalEye, you can:
              </p>
              <br />
              <ul className="list-disc">
                <li className="ml-5">
                  Establish a panel firm arrangement or a network of preferred
                  advisors.
                </li>
                <li className="ml-5">
                  Easily track commitments and monitor and compare performance
                  levels of your advisors.
                </li>
                <li className="ml-5">
                  Access a Discovery Centre of specialist service offerings to
                  drive better value and support.
                </li>
                <li className="ml-5">
                  Initiate data-driven conversations with your advisors.
                </li>
                <li className="ml-5">
                  Collect feedback and evaluate your advisors with LegalEye’s
                  LawScore.
                </li>
              </ul>
              <br />
              <p>
                Standardise and create processes around law firm performance.
                Afterall, reputation should be backed by delivery.
              </p>
            </div>
            <Link to="/learn" className="font-GTWalsheimPro text-leg-red text-xl no-underline font-medium leading-6 flex items-center tracking-[-0.02em]">
              <span>Learn more about Vendor Management</span>
              <img className="ml-3 inline" src={ArrowRight2Icon} />
            </Link>
          </div>
        </div>
      </TabContent>
      <TabContent
        tabId='presence-tab'
        setActiveTabId='presence-tab-set-active'
        setActive={setActive}
      >
        <div id='presence-tab' className="container min-h-[720px] flex flex-col lg:flex-row m-auto py-10 px-6 lg:px-16 lg:py-12">
          <div className="flex items-center justify-center flex-1 p-0 lg:p-10">
            <video className="video" autoPlay loop muted playsInline>
              <source src={'/videos/presence-loop.m4v'} type="video/mp4" />
            </video>
          </div>
          <div className="flex flex-col lg:w-[55%] lg:max-w-[628px] justify-center px-0 pt-12 md:py-14 md:px-11">
            <div id="presence-tab-set-active" className="invisible h-0 relative top-[50%]"></div>
            <div id="presence-tab-top" className="flex items-center">
              <span className="text-xl font-semibold text-dark-gray font-inter">PRESENCE</span>
            </div>
            <h2 className="font-GTWalsheimPro font-medium text-3xl my-4">
              Availability is not credibility
            </h2>
            <div className="font-GTWalsheimPro text-alt-text text-sm leading-7 mb-8">
              <p>
                LinkedIn posts, website updates, industry events, and email
                ‘presence’ simply add to the overwhelm. The modern law firm
                understands the need to fundamentally change the way they
                engage, share knowledge, and market services to their in-house
                peers.
              </p>
              <br />
              <p>
                LegalEye ensures that firms are better able to respond to the
                unique requirements of their clients in order to nurture and
                build upon their relationship. Managing client obligations is
                only possible if you can meet and exceed these expectations in
                the first place. After all, no one wants to be delegated to the
                spam folder.
              </p>
            </div>
            <Link to="/learn" className="font-GTWalsheimPro text-leg-red text-xl no-underline font-medium leading-6 flex items-center tracking-[-0.02em]">
              <span>Learn more about Knowledge & Service Management</span>
              <img className="ml-3 inline" src={ArrowRight2Icon} />
            </Link>
          </div>
        </div>
      </TabContent>
      <TabContent
        tabId='communication-tab'
        setActiveTabId='communication-tab-set-active'
        setActive={setActive}
      >
        <div id="communication-tab" className="container flex flex-col lg:flex-row m-auto py-10 px-6 lg:px-16 lg:py-12">
          <div className="flex items-center justify-center flex-1 p-0 lg:p-10">
            <video className="video" autoPlay loop muted playsInline>
              <source src={'/videos/communication-loop.m4v'} type="video/mp4" />
            </video>
          </div>
          <div className="flex flex-col lg:w-[55%] lg:max-w-[628px] justify-center px-0 pt-12 md:py-14 md:px-11">
            <div id="communication-tab-set-active" className="invisible h-0 relative top-[50%]"></div>
            <div id="communication-tab-top" className="flex items-center">
              <span className="text-xl font-semibold text-dark-gray font-inter">COMMUNICATION</span>
            </div>
            <h2 className="font-GTWalsheimPro font-medium text-3xl my-4">
              The single biggest problem in communication is the illusion it has
              taken place
            </h2>
            <div className="font-GTWalsheimPro text-alt-text text-sm leading-7 mb-8">
              <p>
                The best opportunities for generating new business are via
                existing relationships. In practice, however it is difficult for
                both firms and their clients to leverage these relationships due
                to scattered information and communication silos.
              </p>
              <br />
              <p>
                LegalEye encourages feedback and communication at all stages of
                the law firm and client relationship, before, during and after
                engagements. It makes it possible to discuss opportunities at an
                earlier stage, encouraging a collaborative approach to problem
                solving.
              </p>
              <br />
              <p>
                With LegalEye’s extensive feedback loop, firms are provided
                unparalleled visibility about their performance to ensure they
                can continually meet and surpass the expectations of the clients
                they serve.
              </p>
            </div>
            <Link to="/learn" className="font-GTWalsheimPro text-leg-red text-xl no-underline font-medium leading-6 flex items-center tracking-[-0.02em]">
              <span>Learn more about Continuous Expectation Management</span>
              <img className="ml-3 inline" src={ArrowRight2Icon} />
            </Link>
          </div>
        </div>
      </TabContent>
      <TabContent
        tabId='transparency-tab'
        setActiveTabId='transparency-tab-set-active'
        setActive={setActive}
      >
        <div id="transparency-tab" className="container flex flex-col lg:flex-row m-auto py-10 px-6 lg:px-16 lg:py-12">
          <div className="flex items-center justify-center flex-1 p-0 lg:p-10">
            <video className="video" autoPlay loop muted playsInline>
              <source src={'videos/transparency-loop.m4v'} type="video/mp4" />
            </video>
          </div>
          <div className="flex flex-col lg:w-[55%] lg:max-w-[628px] justify-center px-0 pt-12 md:py-14 md:px-11">
            <div id="transparency-tab-set-active" className="invisible h-0 relative top-[50%]"></div>
            <div id="transparency-tab-top" className="flex items-center">
              <span className="text-xl font-semibold text-dark-gray font-inter">TRANSPARENCY</span>
            </div>
            <h2 className="font-GTWalsheimPro font-medium text-3xl my-4">
              The relationship is in the detail
            </h2>
            <div className="font-GTWalsheimPro text-alt-text text-sm leading-7 mb-8">
              <p>
                Create intelligent RFIs and RFPs that better enable your law
                firms to respond with proposals that speak to the heart of your
                requirements. With LegalEye, we’ll empower you and your law
                firms to better:
              </p>
              <br />
              <ul className="list-disc">
                <li className="ml-5">
                  Scope matters and estimate costs based on existing know-how,
                  past work and industry pricing data.
                </li>
                <li className="ml-5">
                  Understand the proposed scope of engagement, thereby enabling
                  AFAs.
                </li>
                <li className="ml-5">
                  Forecast legal spend, with insight into potential risks for
                  matter scope and cost blow-outs.
                </li>
                <li className="ml-5">
                  Accelerate the tender process with automated workflows that
                  align to key deadlines and events.
                </li>
              </ul>
              <br />
              <p>
                With unparalleled insight into how a matter will be conducted,
                teams are better able to collaborate and make data-informed
                decisions, increasing the likelihood of success.
              </p>
            </div>
            <Link to="/learn" className="font-GTWalsheimPro text-leg-red text-xl no-underline font-medium leading-6 flex items-center tracking-[-0.02em]">
              <span>Learn more about Matter Scoping & Tender Management</span>
              <img className="ml-3 inline" src={ArrowRight2Icon} />
            </Link>
          </div>
        </div>
      </TabContent>
    </div>
  )
}

export default Features
