import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'

import Layout from '../components/layout'
import MasterClass from '../components/masterclass'
import Features from '../components/features'
import BookDemoButton from '../components/book-demo-button'

import ArrowRightIcon from '../assets/images/icons/arrow-right-2.svg'
import Shapes from '../assets/images/shapes.png'
import Circle from '../assets/images/circle.png'
import ContinuousLearning from '../assets/images/continuous-learning.png'

import SimmonsAndSimmonsLogo from '../assets/images/logos/simmons-and-simmons.png'
import LinklatersLogo from '../assets/images/logos/linklaters.png'
import AllenAndOveryLogo from '../assets/images/logos/allen-and-overy.png'
import AshurstLogo from '../assets/images/logos/ashurst.png'
import BirdAndBirdLogo from '../assets/images/logos/bird-and-bird.png'
import PaulHastingsLogo from '../assets/images/logos/paul-hastings.png'
import PinsentMasonsLogo from '../assets/images/logos/pinsent-masons.png'
import BarclaysEagleLabsLogo from '../assets/images/logos/barclays-eagle-labs.png'
import LonelyPlanetLogo from '../assets/images/logos/lonely-planet.png'
import PexaLogo from '../assets/images/logos/pexa.png'
import KrollLogo from '../assets/images/logos/kroll.png'
import TltLogo from '../assets/images/logos/tlt.png'

import ISO27001Icon from '../assets/images/icons/iso-27001.png'
import CyberEssentialsPlusIcon from '../assets/images/icons/cyber-essentials-plus.png'

function IndexPage () {
  return (
    <Layout>
      <div className="md:hero-wrapper lg:h-[960px] flex items-center">
        <div className="container md:px-8 mx-auto grid items-center md:gap-40 lg-grid-cols-1 md:grid-cols-2">
          <div className="py-5 px-6 md:py-0 md:px-0">
            <div className="flex flex-col justify-center overflow-x-hidden">
              <h1 className="text-3xl mt-6 lg:text-6xl lg:leading-tight font-GTWalsheimPro font-medium">
                Powering smarter
              </h1>
              <Slider vertical autoplay>
                <h1 className="text-3xl text-leg-red lg:text-6xl lg:leading-tight font-GTWalsheimPro font-medium">
                  engagements
                </h1>
                <h1 className="text-3xl text-leg-red lg:text-6xl lg:leading-tight font-GTWalsheimPro font-medium">
                  business
                </h1>
                <h1 className="text-3xl text-leg-red lg:text-6xl lg:leading-tight font-GTWalsheimPro font-medium">
                  relationships
                </h1>
              </Slider>
              <h1 className="text-3xl lg:text-6xl  lg:leading-tight font-GTWalsheimPro font-medium">
                between in-house and law firms
              </h1>
              <h2 className="text-xl lg:text-4xl lg:leading-tight font-GTWalsheimPro mt-6">
                Supercharged panel firm and client management
              </h2>
              <BookDemoButton buttonClass="font-GTWalsheimPro self-start font-medium mt-10 text-lg rounded-full px-8 py-4 bg-leg-red text-white inline-block" />
            </div>
          </div>
          <div className="row-start-1 bg-[#F3F5F9] row-end-2 md:row-auto">
            <video
              className="mx-auto h-[400px] md:h-full"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={'/videos/hero-loop.m4v'} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="py-12 md:py-5 px-6 lg:p-32">
        <div className="w-full lg:w-[800px] m-auto text-center">
          <video className="video" autoPlay muted playsInline>
            <source src={'/videos/organisation-loop.m4v'} type="video/mp4" />
          </video>
          <h2 className="font-GTWalsheimPro font-medium text-3xl lg:text-5xl lg:leading-tight">
            Break free from the silos to achieve
            <br />
            <span className="font-bold">legal operations excellence</span>
          </h2>
          <div className="font-GTWalsheimPro font-normal text-lg mt-6 leading-loose">
            LegalEye is the industry’s leading legal operations platform that
            understands the complex makeup of your organisation, and unlocks it,
            to transform and drive better business outcomes.
          </div>
        </div>
      </div>
      <div className="container mx-auto md:px-8">
        <div className="px-6 md:pt-24 md:px-[72px] mb-24 lg:mb-30 relative">
          <img src={Shapes} className="absolute z-[-1] w-[199px] md:w-[384px] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] md:translate-y-0 md:translate-x-0 md:left-0" />
          <img src={Circle} className="hidden md:block absolute h-[188px] top-0 right-0" />
          <div className="grid gap-[128px] md:gap-0 md:grid-cols-[auto_auto_72px] md:grid-rows-[1fr_72px_auto]">
            <div className="shadow-xs px-6 md:px-12 lg:px-20 py-8 md:py-12 lg:py-16 w-fit md:col-start-1 md:col-end-3 md:row-start-1 md:row-end-3 rounded bg-white">
              <h1 className="text-2xl lg:text-5xl lg:leading-tight font-GTWalsheimPro font-medium w-full">
                LegalEye is powering the world&apos;s best legal operations departments.
              </h1>
              <Link
                className="font-GTWalsheimPro text-3xl text-leg-red mt-6 flex items-center"
                to="/about"
              >
                About Us
                <img className="ml-3" src={ArrowRightIcon} />
              </Link>
            </div>
            <div className="px-6 md:px-12 lg:px-20 md:py-10 md:shadow-xs md:col-start-2 md:col-end-4 md:row-start-2 md:row-end-4 bg-white rounded">
              <div className="font-GTWalsheimPro text-xl text-center">Join our <span className="text-leg-red">Steering Group</span></div>
              <div className="grid grid-cols-4 gap-4 md:gap-6 lg:gap-8 mt-8 items-baseline">
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={SimmonsAndSimmonsLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={LinklatersLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={AllenAndOveryLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={AshurstLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={BirdAndBirdLogo} />
                <img className="h-4 md:h-6 lg:h-12 mx-auto opacity-40 object-contain" src={PinsentMasonsLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={PaulHastingsLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={BarclaysEagleLabsLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={KrollLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={LonelyPlanetLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={PexaLogo} />
                <img className="h-4 md:h-6 lg:h-8 mx-auto opacity-40 object-contain" src={TltLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mb-12 px-6 lg:px-0">
        <div className="w-full lg:w-[800px] m-auto text-center">
          <h2 className="font-GTWalsheimPro font-medium text-3xl lg:text-5xl lg:leading-tight">
            Technology to <b>enhance</b> human collaboration, not to replace it
          </h2>
          <div className="font-GTWalsheimPro font-normal mt-6 leading-loose">
            Supercharge instructions and RFPs, provide clients, or nurturing
            relationships and value from suppliers. LegalEye is the leading
            legal operations platform for in-house and for firms that can
            develop trust, increase transparency, open lines of communication
            and boost your presence to ensure healthy business and working
            relationships.
          </div>
        </div>
      </div>
      <Features />
      <div className="bg-light-gray">
        <MasterClass />
      </div>
      <div className="py-5 px-6 lg:p-32 bg-light-gray">
        <div className="container mx-auto">
          <img src={ContinuousLearning} className="mx-auto w-full md:w-3/4" />
          <div className="w-full lg:w-[800px] mt-10 m-auto text-center">
            <h3 className="font-GTWalsheimPro font-medium text-3xl lg:text-5xl">
              Continuous <b>Learning</b>
            </h3>
            <div className="font-GTWalsheimPro mt-6 font-normal leading-loose text-center">
              LegalEye has been purpose built to better enable law firms and
              in-house teams to learn and grow together. LegalEye helps to retain
              the knowledge it creates for you and stores it in a way that is
              easily accessible and can be used for future and new business.
            </div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="w-full mt-10 m-auto text-center">
          <h3 className="font-GTWalsheimPro font-medium text-3xl lg:text-5xl">
            Certifications
          </h3>
          <div className="w-full mt-16 flex flex-col items-center lg:flex-row lg:items-start lg:justify-between">
            <div className="flex flex-col items-center mb-12 lg:w-[50%] lg:flex-row lg:items-start lg:text-left">
              <div className="w-[76px] lg:w-[120px] lg:mr-10">
                <img className="mx-auto lg:mr-0 lg:ml-auto" src={ISO27001Icon} />
              </div>
              <div className="lg:max-w-[312px]">
                <div className="font-GTWalsheimPro mt-2 lg:mt-0 font-normal text-xl leading-8 tracking-[-0.02em]">
                  ISO27001
                </div>
                <div className="text-alt-text text-sm leading-7">
                  The most rigorous global security standard for Information Security Management Systems (ISMS)
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mb-12 lg:w-[50%] lg:flex-row lg:items-start lg:text-left">
              <div className="w-[76px] lg:w-[120px] lg:mr-10">
                <img className="mx-auto lg:mr-0 lg:ml-auto" src={CyberEssentialsPlusIcon} />
              </div>
              <div className="lg:max-w-[312px]">
                <div className="font-GTWalsheimPro mt-2 lg:mt-0 font-normal text-xl leading-8 tracking-[-0.02em]">
                  Cyber Essentials Plus
                </div>
                <div className="text-alt-text text-sm leading-7">
                  A scheme backed by the UK Government that helps to protect organisations against a range of common cyber attacks
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#191819] relative overflow-hidden">
        <video
          className="hidden lg:block absolute z-0 top-[30%] right-[60%] translate-x-[40%] w-[50%]"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={'/videos/globe-desktop-loop.m4v'} type="video/mp4" />
        </video>
        <div className="py-10 px-6 relative lg:py-32">

          <h2 className="font-GTWalsheimPro text-2xl lg:text-5xl text-center text-white">
            Enabling today&apos;s normal
          </h2>
          <div className="font-GTWalsheimPro font-normal mt-6 leading-loose text-center text-white">
            LegalEye powers multinationals to do business globally, at pace.
          </div>
          <video
            className="max-h-[400px] mx-auto lg:hidden text-white"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={'/videos/globe-desktop-loop.m4v'} type="video/mp4" />
          </video>
          <div className="w-full container m-auto lg:mt-[120px] text-white">
            <div style={{ width: 'fit-content' }} className="lg:ml-auto">
              <div className="border-l border-white px-2 lg:px-4">
                <h3 className="font-GTWalsheimPro text-3xl lg:text-5xl">30+</h3>
                <div className="mt-1 font-inter">
                  multinational law firms onboarded
                </div>
              </div>
              <div className="border-l border-white px-2 lg:px-4 mt-8 lg:mt-16">
                <h3 className="font-GTWalsheimPro text-3xl lg:text-5xl">
                  2,000+
                </h3>
                <div className="mt-1 font-inter">lawyers onboarded</div>
              </div>
              <div className="border-l border-white px-2 lg:px-4 mt-8 lg:mt-16">
                <h3 className="font-GTWalsheimPro text-3xl lg:text-5xl">20%</h3>
                <div className="mt-1 font-inter">
                  20% reduction in time and costs associated with instructions
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
