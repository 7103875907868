import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'
import isInViewport from '../../utils/is-in-viewport'

const TabContent = ({ tabId, setActiveTabId, setActive, children }) => {
  useEffect(() => {
    window.addEventListener('scroll', setActiveTab)
    return () => window.removeEventListener('scroll', setActiveTab)
  })

  const setActiveTab = _debounce(() => {
    const el = document.getElementById(setActiveTabId)
    isInViewport(el) && setActive(tabId)
  }, 20)

  return (
    <div>{children}</div>
  )
}

TabContent.propTypes = {
  tabId: PropTypes.string,
  setActiveTabId: PropTypes.string,
  setActive: PropTypes.func,
  children: PropTypes.element
}

export default TabContent
