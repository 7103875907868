import React, { useState, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/virtual'

import VimeoPlayer from '@vimeo/player'

import MasterClassLogo from '../assets/images/logos/masterclass.svg'
import MasterClassMobileLogo from '../assets/images/logos/masterclass-mobile.svg'
import ByLawAdvisorVentures from '../assets/images/logos/by-lawadvisor-ventures.svg'
import Teaser1 from '../assets/images/masterclass/teaser-1.png'
import Teaser2 from '../assets/images/masterclass/teaser-2.png'
import Teaser3 from '../assets/images/masterclass/teaser-3.jpg'
import Teaser4 from '../assets/images/masterclass/teaser-4.png'
import TeaserMobile4 from '../assets/images/masterclass/teaser-mobile-4.png'
import Chevron from '../assets/images/chevron.svg'
import PlayIcon from '../assets/images/play.svg'
import PlayTrailerIcon from '../assets/images/play-trailer.png'
import LarsRasmussen from '../assets/images/masterclass/lars-rasmussen.png'
import DavidKerr from '../assets/images/masterclass/david-kerr.png'
import JasonBarnwell from '../assets/images/masterclass/jason-barnwell.png'
import HelgaButcher from '../assets/images/masterclass/helga-butcher.png'
import StephanieHamon from '../assets/images/masterclass/stephanie-hamon.png'
import PaulHogg from '../assets/images/masterclass/paul-hogg.png'
import StephanieHamonChrisGrant from '../assets/images/masterclass/stephanie-hamon-chris-grant.png'
import CatherineGoodman from '../assets/images/masterclass/catherine-goodman.png'
import RalphBaxter from '../assets/images/masterclass/ralph-baxter.png'
import GaryTully from '../assets/images/masterclass/gary-tully.png'
import ChrisGrant from '../assets/images/masterclass/chris-grant.png'
import CloseIcon from '../assets/images/icons/close.svg'

const Speaker = ({ imageClass, name, title, position, duration, onClick, speakers = [] }) => (
  <div className={`cursor-pointer w-[232px] h-[368px] bg-cover mx-auto rounded rounded-lg ${imageClass}`} onClick={onClick}>
    <div className="h-full flex flex-col justify-end text-white text-center px-3 pb-5">
      {speakers.map(({ name, position }) => (
        <Fragment key={name}>
          <h3 className="font-GTWalsheimPro font-semibold text-2xl">{name}</h3>
          <div className="font-GTWalsheimPro text-[11px] tracking-tight whitespace-pre-wrap leading-tight">{position}</div>
        </Fragment>
      ))}
      <div className="font-GTWalsheimPro text-[11px] tracking-tight whitespace-pre-wrap leading-tight mt-2">{title}</div>
      <div className="font-GTWalsheimPro text-[11px] font-light mt-2">{duration}</div>
    </div>
  </div>
)

Speaker.propTypes = {
  imageClass: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.string,
  onClick: PropTypes.func
}

const Player = ({ currentVideo: { source, getStartedText, getStartedURL } }) => {
  const [initialSource, setInitialSource] = useState(null)
  const player = useRef(null)
  const [playing, setPlaying] = useState(true)
  const playerController = useRef(null)

  useEffect(() => {
    if (!initialSource && source) {
      setInitialSource(source)
    }

    setPlaying(true)
    const player = document.getElementById('vimeo-iframe')
    if (player !== null) {
      playerController.current = new VimeoPlayer(player)

      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    if (playerController.current !== null && source !== initialSource) {
      playerController.current.loadVideo(source).then(() => {
        playerController.current.play()
      })
    }
  }, [source, initialSource])

  return (
    <div className="relative">
      <div
        style={{ padding: '42.19% 0 0 0', position: 'relative' }}
      >
        {initialSource && (
          <iframe
            id="vimeo-iframe"
            ref={player}
            src={initialSource}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        )}
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {
        !playing && (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,_0,_0,_0.4)] z-10 pointer-events-none flex flex-col items-center justify-center">
            <div
              className="font-GTWalsheimPro font-medium text-white text-5xl text-center max-w-[640px]"
            >
              {getStartedText}
            </div>
            <Link
              className="font-GTWalsheimPro font-medium text-lg pointer-events-auto text-white py-2 px-4 border border-white rounded-full mt-5"
              to={getStartedURL}>
              Get Started
            </Link>
          </div>
        )
      }
    </div>
  )
}

const MobilePlayer = ({ currentVideo: { source }, onClose }) => {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const playerController = useRef(null)

  useEffect(() => {
    if (player != null) {
      playerController.current = new VimeoPlayer(player.current)
      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    document.getElementsByTagName('body')[0].classList.add('overflow-y-hidden')
    document.getElementsByTagName('main')[0].classList.add('overflow-y-hidden')

    return () => {
      document.getElementsByTagName('body')[0].classList.remove('overflow-y-hidden')
      document.getElementsByTagName('main')[0].classList.remove('overflow-y-hidden')
    }
  }, [])

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-main-black z-50 h-screen w-screen flex items-center">
      <button className="absolute top-8 right-8" onClick={() => onClose()}>
        <img src={CloseIcon} />
      </button>
      <div className="relative w-full">
        <div
          style={{ padding: '42.19% 0 0 0', position: 'relative' }}
        >
          <iframe
            ref={player}
            src={source}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

const MasterClass = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [mobileIsPlaying, setMobileIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)
  const [mobileCurrentVideo, setMobileCurrentVideo] = useState(null)

  return (
    <>
      <div className="hidden md:block">
        <div className="bg-main-black pt-30 pb-12">
          <div className="container mx-auto">
            <div className="flex justify-between items-end">
              <img className="h-20" src={MasterClassLogo} />
              <img className="h-6" src={ByLawAdvisorVentures} />
            </div>
            <div className="mt-[72px]">
              {
                isPlaying
                  ? (<Player
                      currentVideo={currentVideo}
                    />)
                  : (
                    <div className="grid grid-cols-2 rounded-md">
                      <button
                          className="relative"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                      >
                        <img className="w-full rounded-l-md" src={Teaser1} />
                        <img className="absolute top-1/2 left-1/2 -translate-y-1/2" src={PlayIcon} />
                      </button>
                      <div className="hidden md:flex flex-col justify-center px-[36px] lg:px-[48px] xl:px-[72px] text-white font-inter bg-[#1C1924] rounded-r-md">
                        <button
                          className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                        >
                          <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
                          <div>
                            Watch Trailer
                          </div>
                        </button>
                        <h2 className="font-GTWalsheimPro font-medium text-2xl lg:text-4xl xl:text-5xl lg:leading-tight mt-6 lg:mt-8">
                          The Industry Titans
                        </h2>
                        <p className="font-GTWalsheimPro text-base lg:text-lg xl:text-xl mt-4 lg:mt-6 leading-loose">
                          Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                          <br className="hidden lg:block" />
                          <br className="hidden lg:block" />
                          <span className="hidden lg:block">#legaltech, it’s time!</span>
                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                    )
              }
            </div>
          </div>
          <div className="flex items-center text-white font-bold text-xl w-fit mx-auto mt-20">
            <div className="mr-5">SPEAKERS</div>
            <Link to="/learn" className="bg-[#FF4B6C] px-8 py-2 rounded-full leading-relaxed">Learn more</Link>
          </div>
        </div>
        <div className="bg-[linear-gradient(to_bottom,_#110D1B_50%,_transparent_50%)]">
          <div className="container mx-auto relative">
            <div className="grid grid-cols-3 w-fit gap-3 mx-auto">
              <Speaker
                imageClass="bg-lars-rasmussen cursor-pointer"
                name="Lars Rasmussen"
                title={`Lawyers and Technologists${'\n'}the twain shall meet`}
                duration="11m 17s"
                position={`Founder of Google Maps${'\n'}Former Director of Engineering for Facebook`}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: 'Watch Lars Rasmussen\'s Masterclass now',
                    getStartedURL: '/learn/lawyers-and-technologists'
                  })
                }}
              />
              <Speaker
                imageClass="bg-david-kerr cursor-pointer"
                name="David Kerr"
                title={`Judgment Day${'\n'}the rise of the tech-enabled law firm`}
                duration="8m 14s"
                position={`Former CEO of Bird & Bird${'\n'} `}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: 'Watch David Kerr\'s Masterclass now',
                    getStartedURL: '/learn/judgment-day'
                  })
                }}
              />
              <Speaker
                imageClass="bg-jason-barnwell cursor-pointer"
                name="Jason Barnwell"
                duration="6m 30s"
                position={`Microsoft's Corporate Attorney${'\n'}for External and Legal Affairs`}
                title={`Legal Innovation${'\n'}We can do more`}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: 'Watch Jason Barnwell\'s Masterclass now',
                    getStartedURL: '/learn/legal-innovation'
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div className="bg-main-black py-10">
          <img src={MasterClassMobileLogo} className="h-16 mx-auto mb-8" />
          <div
            className="px-4"
          >
            <img src={Teaser1} className="w-full h-[144px] object-cover object-top rounded rounded-lg" />
            <button
              className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit mx-auto mt-10"
              onClick={() => {
                setMobileIsPlaying(true)
                setMobileCurrentVideo({
                  source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                })
              }}
            >
              <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
              <div>
                Watch Trailer
              </div>
            </button>
            <div
              className="mt-5"
            >
              <div className="text-white text-center font-GTWalsheimPro">
                <h3 className="text-2xl font-medium">
                  The Industry Titans
                </h3>
                <div className="mt-6">
                  Learn the secrets from the battlefield in a series of masterclasses taught by the most influential thinkers in the field.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[linear-gradient(to_bottom,_#110D1B_50%,_#FFFFFF_50%)] px-4">
          <div className="grid grid-cols-3 gap-2 mx-auto">
            <img
              src={LarsRasmussen}
              className="flex-1 object-cover rounded rounded-lg cursor-pointer"
              onClick={() => {
                setMobileIsPlaying(true)
                setMobileCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                })
              }}
            />
            <img
              src={DavidKerr}
              className="flex-1 object-cover rounded rounded-lg cursor-pointer"
              onClick={() => {
                setMobileIsPlaying(true)
                setMobileCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                })
              }}
            />
            <img
              src={JasonBarnwell}
              className="flex-1 object-cover rounded rounded-lg cursor-pointer"
              onClick={() => {
                setMobileIsPlaying(true)
                setMobileCurrentVideo({
                  source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                })
              }}
            />
          </div>
        </div>
      </div>
      {mobileIsPlaying &&
        (<MobilePlayer
          onClose={() => setMobileIsPlaying(false)}
          currentVideo={mobileCurrentVideo}
        />)}
    </>
  )
}

const DesktopMasterClassNew = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState({
    source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
    getStartedText: 'Watch Masterclass now',
    getStartedURL: '/learn'
  })

  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <div>
      <div className="bg-main-black pt-[120px] pb-12">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <img className="h-20" src={MasterClassLogo} />
            <img className="h-6" src={ByLawAdvisorVentures} />
          </div>
          <div className="mt-[72px]">
            {isPlaying && (
              <Player
                currentVideo={currentVideo}
              />
            )}
            {
              <div className={`${isPlaying ? 'hidden' : 'block'}`}>
                <Swiper
                  loop
                  allowTouchMove={false}
                  onSwiper={setFirstSwiper}
                  onSlideChange={(s) => {
                    setIsPlaying(false)
                    // setActiveSlide(s.realIndex)
                  }}
                >
                  <SwiperSlide>
                    <div className="grid grid-cols-2 rounded-md">
                      <button
                        className="relative"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                            getStartedText: 'Watch Masterclass now',
                            getStartedURL: '/learn'
                          })
                        }}
                      >
                        <img className="w-full rounded-l-md" src={Teaser2} />
                        <img className="absolute top-1/2 left-1/2 -translate-y-1/2" src={PlayIcon} />
                      </button>
                      <div className="hidden md:flex flex-col justify-center px-[36px] lg:px-[48px] xl:px-[72px] text-white font-inter bg-[#1C1924] rounded-r-md">
                        <button
                          className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                        >
                          <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
                          <div>
                            Watch Teaser
                          </div>
                        </button>
                        <h2 className="font-GTWalsheimPro font-medium text-2xl lg:text-4xl lg:leading-tight mt-6 lg:mt-8">
                          Panel Firms - it’s all <br />about the relationships!
                        </h2>
                        <p className="font-GTWalsheimPro text-base lg:text-lg xl:text-xl mt-4 lg:mt-6 leading-loose">
                          As a relationship manager at Barclays, Paul Hogg discusses - through first hand experience - why panel firms are important and the value that in house departments like Barclays are seeking from the law firms within their panel.
                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="grid grid-cols-2 rounded-md">
                      <button
                        className="relative"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: 'https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                            getStartedText: 'Watch Masterclass now',
                            getStartedURL: '/learn'
                          })
                        }}
                      >
                        <img className="w-full rounded-l-md" src={Teaser3} />
                        <img className="absolute top-1/2 left-1/2 -translate-y-1/2" src={PlayIcon} />
                      </button>
                      <div className="hidden md:flex flex-col justify-center px-[36px] lg:px-[48px] xl:px-[72px] text-white font-inter bg-[#1C1924] rounded-r-md">
                        <button
                          className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                        >
                          <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
                          <div>
                            Watch Trailer
                          </div>
                        </button>
                        <h2 className="font-GTWalsheimPro font-medium text-2xl lg:text-4xl lg:leading-tight mt-6 lg:mt-8">
                          Legal Operations in Practice
                        </h2>
                        <p className="font-GTWalsheimPro text-base lg:text-lg xl:text-xl mt-4 lg:mt-6 leading-loose">
                          Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, the burgeoning role of legal project managers, and why legal operations is here to stay.
                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="grid grid-cols-2 rounded-md">
                      <button
                        className="relative"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                            getStartedText: 'Watch Masterclass now',
                            getStartedURL: '/learn'
                          })
                        }}
                      >
                        <img className="w-full rounded-l-md" src={Teaser1} />
                        <img className="absolute top-1/2 left-1/2 -translate-y-1/2" src={PlayIcon} />
                      </button>
                      <div className="hidden md:flex flex-col justify-center px-[36px] lg:px-[48px] xl:px-[72px] text-white font-inter bg-[#1C1924] rounded-r-md">
                        <button
                          className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                        >
                          <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
                          <div>
                            Watch Trailer
                          </div>
                        </button>
                        <h2 className="font-GTWalsheimPro font-medium text-2xl lg:text-4xl lg:leading-tight mt-6 lg:mt-8">
                          The Industry Titans
                        </h2>
                        <p className="font-GTWalsheimPro text-base lg:text-lg xl:text-xl mt-4 lg:mt-6 leading-loose">
                          Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                          <br className="hidden lg:block" />
                          <br className="hidden lg:block" />
                          <span className="hidden lg:block">#legaltech, it’s time!</span>
                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="grid grid-cols-2 rounded-md">
                      <button
                        className="relative"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                            getStartedText: 'Watch Masterclass now',
                            getStartedURL: '/learn'
                          })
                        }}
                      >
                        <img className="w-full rounded-l-md" src={Teaser4} />
                        <img className="absolute top-1/2 left-1/2 -translate-y-1/2" src={PlayIcon} />
                      </button>
                      <div className="hidden md:flex flex-col justify-center px-[36px] lg:px-[48px] xl:px-[72px] text-white font-inter bg-[#1C1924] rounded-r-md">
                        <button
                          className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit"
                          onClick={() => {
                            setIsPlaying(true)
                            setCurrentVideo({
                              source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                              getStartedText: 'Watch Masterclass now',
                              getStartedURL: '/learn'
                            })
                          }}
                        >
                          <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
                          <div>
                            Watch Trailer
                          </div>
                        </button>
                        <h2 className="font-GTWalsheimPro font-medium text-2xl lg:text-4xl lg:leading-tight mt-6 lg:mt-8">
                          Harnessing the power of technology to become better lawyers
                        </h2>
                        <p className="font-GTWalsheimPro text-base lg:text-lg xl:text-xl mt-4 lg:mt-6 leading-loose">
                          Ralph Baxter talks about the importance of innovation and how it can make legal services better. He highlights the role of modern tools and technology in helping lawyers become better at what they do.
                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            }
          </div>
        </div>
        <div>
          <div className="flex mt-8 mx-auto w-fit">
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 0 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                setActiveSlide(0)
                firstSwiper.slideTo(1)
                secondSwiper.slideTo(1)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 1 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                setActiveSlide(1)
                firstSwiper.slideTo(2)
                secondSwiper.slideTo(1)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 2 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                setActiveSlide(2)
                firstSwiper.slideTo(2)
                firstSwiper.slideTo(3)
                secondSwiper.slideTo(2)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 3 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                setActiveSlide(3)
                firstSwiper.slideTo(3)
                firstSwiper.slideTo(4)
                secondSwiper.slideTo(3)
              }}
            />
          </div>
          <div className="flex items-center text-white font-bold text-xl w-fit mx-auto mt-6">
            <div className="mr-5">SPEAKERS</div>
            <Link to="/learn" className="bg-[#FF4B6C] px-8 py-2 rounded-full leading-relaxed">Learn more</Link>
          </div>
        </div>
      </div>
      <div className="bg-[linear-gradient(to_bottom,_#110D1B_50%,_#F3F5F9_50%)]">
        <div className="container relative mx-auto">
          <Swiper
            loop
            allowTouchMove={false}
            onSwiper={setSecondSwiper}
            onSlideChange={() => {
              setIsPlaying(false)
            }}
          >
            <SwiperSlide>
              <div className="grid grid-cols-5 w-fit gap-3 mx-auto">
                <Speaker
                  imageClass="bg-stephanie-hamon-chris-grant cursor-pointer"
                  duration="20m 20s"
                  title={`LegalOps${'\n'}What's the fuss?`}
                  speakers={[
                    { name: 'Stéphanie Hamon', position: `Head of Legal Operations Consulting,${'\n'}Norton Rose Fulbright` },
                    { name: 'Chris Grant', position: `Lawtech / LegalOps Consultant${'\n'}for startups & lawfirms` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718640127?h=2bbd968b86&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Stéphanie Hamon and Chris Grant\'s Masterclass now',
                      getStartedURL: '/learn/legal-ops-whats-the-fuss'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-paul-hogg"
                  duration="9m 27s"
                  title={`Panel Firms${'\n'}It's all about the relationships!`}
                  speakers={[
                    { name: 'Paul Hogg', position: `Relationship Manager,${'\n'}Barclays` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Paul Hogg\'s Masterclass now',
                      getStartedURL: '/learn/panel-firms'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-catherine-goodman"
                  duration="5m 37s"
                  title={`Paving the way for${'\n'}#legaltech adoption`}
                  speakers={[
                    { name: 'Catherine Goodman', position: `Practice Innovation & Knowledge Counsel${'\n'}Paul Hastings` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Catherine Goodman\'s Masterclass now',
                      getStartedURL: '/learn/paving-the-way-for-legaltech-adoption'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-helga-butcher"
                  duration="8m 13s"
                  title={`Legal Project Management${'\n'}it's here to stay`}
                  speakers={[
                    { name: 'Helga Butcher', position: `Head of Legal Project Management${'\n'}Ashurst` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Helga Butcher\'s Masterclass now',
                      getStartedURL: '/learn/legal-project-management'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-stephanie-hamon"
                  duration="12m 37s"
                  title={`LegalOps${'\n'}a framework for success`}
                  speakers={[
                    { name: 'Stéphanie Hamon', position: `Head of Legal Operations Consulting,${'\n'}Norton Rose Fulbright` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718644038?h=2a87edb5ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Stéphanie Hamon\'s Masterclass now',
                      getStartedURL: '/learn/legal-ops-a-framework-for-success'
                    })
                  }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="grid grid-cols-3 w-fit gap-3 mx-auto">
                <Speaker
                  imageClass="bg-lars-rasmussen cursor-pointer"
                  title={`Lawyers and Technologists${'\n'}the twain shall meet`}
                  duration="11m 17s"
                  speakers={[
                    { name: 'Lars Rasmussen', position: `Founder of Google Maps${'\n'}Former Director of Engineering for Facebook` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Lars Rasmussen\'s Masterclass now',
                      getStartedURL: '/learn/lawyers-and-technologists'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-david-kerr cursor-pointer"
                  title={`Judgment Day${'\n'}the rise of the tech-enabled law firm`}
                  duration="8m 14s"
                  speakers={[
                    { name: 'David Kerr', position: `Former CEO of Bird & Bird${'\n'} ` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch David Kerr\'s Masterclass now',
                      getStartedURL: '/learn/judgment-day'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-jason-barnwell cursor-pointer"
                  name="Jason Barnwell"
                  duration="6m 30s"
                  position={''}
                  title={`Legal Innovation${'\n'}We can do more`}
                  speakers={[{ name: 'Jason Barnwell', position: `Microsoft's Corporate Attorney${'\n'}for External and Legal Affairs` }]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Jason Barnwell\'s Masterclass now',
                      getStartedURL: '/learn/legal-innovation'
                    })
                  }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="grid grid-cols-2 w-fit gap-3 mx-auto">
                <Speaker
                  imageClass="bg-chris-grant cursor-pointer"
                  title={`Digital Transformation and the${'\n'}Modernisation of Legal Operations`}
                  duration="10m 53s"
                  speakers={[
                    { name: 'Chris Grant', position: 'Investor & Advisor' }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Chris Grant\'s Masterclass now',
                      getStartedURL: '/learn/digital-transformation'
                    })
                  }}
                />
                <Speaker
                  imageClass="bg-ralph-baxter cursor-pointer"
                  title={`Harnessing the power of technology to${'\n'}become better lawyers`}
                  duration="8m 14s"
                  speakers={[
                    { name: 'Ralph Baxter', position: `Former Chairman and CEO,${'\n'}of Orrick` }
                  ]}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: 'Watch Ralph Baxter\'s Masterclass now',
                      getStartedURL: '/learn/harnessing-the-power-of-technology'
                    })
                  }}
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <button
            className="absolute -left-10 top-[50%] translate-y-[-50%] bg-[#FF4B6C] rounded-full h-10 w-10 flex items-center justify-center"
            onClick={() => {
              setIsPlaying(false)

              const prev = ((activeSlide - 1) + 4) % 4
              setActiveSlide(prev)

              // slideTo is 1 base
              firstSwiper.slideTo(prev + 1)

              if (prev === 0 || prev === 1) {
                secondSwiper.slideTo(1)
              } else if (prev === 2) {
                secondSwiper.slideTo(2)
              } else {
                secondSwiper.slideTo(3)
              }
            }}
          >
            <img src={Chevron} className="h-5 mr-1" />
          </button>
          <button
            className="absolute -right-10 top-[50%] translate-y-[-50%] bg-[#FF4B6C] rounded-full h-10 w-10 flex items-center justify-center"
            onClick={() => {
              setIsPlaying(false)
              const next = ((activeSlide + 1)) % 4
              setActiveSlide(next)

              // slideTo is 1 base
              firstSwiper.slideTo(next + 1)

              if (next === 0 || next === 1) {
                secondSwiper.slideTo(1)
              } else if (next === 2) {
                secondSwiper.slideTo(2)
              } else {
                secondSwiper.slideTo(3)
              }
            }}
          >
            <img src={Chevron} className="h-5 scale-x-[-1] ml-1" />
          </button>
        </div>
      </div>
    </div>
  )
}

const MobileMasterClassNew = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <>
      <div className="bg-main-black py-10">
        <img src={MasterClassMobileLogo} className="h-16 mx-auto mb-8" />
        <div className="px-4">
          <Swiper
            loop
            modules={[Controller]}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            onSlideChange={(s) => {
              setActiveSlide(s.realIndex)
              setIsPlaying(false)
            }}
          >
            <SwiperSlide>
              <img src={Teaser2} className="w-full h-[144px] md:h-[320px] object-cover object-top rounded rounded-lg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Teaser3} className="w-full h-[144px] md:h-[320px] object-cover object-top rounded rounded-lg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Teaser1} className="w-full h-[144px] md:h-[320px] object-cover object-top rounded rounded-lg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={TeaserMobile4} className="w-full h-[144px] md:h-[320px] object-cover object-top rounded rounded-lg" />
            </SwiperSlide>
          </Swiper>
          <button
            className="font-GTWalsheimPro text-leg-red border border-leg-red rounded-full py-2 px-4 flex items-center w-fit mx-auto mt-10"
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
              })
            }}
          >
            <img src={PlayTrailerIcon} className="h-5 w-5 mr-2" />
            <div>
              {activeSlide === 0 ? 'Watch Teaser' : 'Watch Trailer'}
            </div>
          </button>
          <div className="flex my-4 mx-auto w-fit">
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 0 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                firstSwiper.slideTo(1)
                secondSwiper.slideTo(1)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 1 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                firstSwiper.slideTo(2)
                secondSwiper.slideTo(2)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 2 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                firstSwiper.slideTo(3)
                secondSwiper.slideTo(3)
              }}
            />
            <button
              className={`h-3 w-3 rounded-full mx-2 ${activeSlide === 3 ? 'bg-[#FF4B6C] ' : 'bg-[#8A8D99] '}`}
              onClick={() => {
                firstSwiper.slideTo(4)
                secondSwiper.slideTo(4)
              }}
            />
          </div>
          <Swiper
            loop
            modules={[Controller]}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
          >
            <SwiperSlide>
              <div className="text-white text-center font-GTWalsheimPro">
                <h3 className="text-2xl md:text-3xl font-medium">
                  Panel Firms - it’s all about the relationships!
                </h3>
                <div className="text-base md:text-lg mt-6">
                  As a relationship manager at Barclays, Paul Hogg discusses - through first hand experience - why panel firms are important and the value that in house departments like Barclays are seeking from the law firms within their panel.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-white text-center font-GTWalsheimPro">
                <h3 className="text-2xl md:text-3xl font-medium">
                  Legal Operations in Practice
                </h3>
                <div className="text-base md:text-lg mt-6">
                  Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, the burgeoning role of legal project managers, and why legal operations is here to stay.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-white text-center font-GTWalsheimPro">
                <h3 className="text-2xl md:text-3xl font-medium">
                  The Industry Titans
                </h3>
                <div className="text-base md:text-lg mt-6">
                  Learn the secrets from the battlefield in a series of masterclasses taught by the most influential thinkers in the field.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-white text-center font-GTWalsheimPro">
                <h3 className="text-2xl md:text-3xl font-medium">
                  Harnessing the power of technology to become better lawyers
                </h3>
                <div className="text-base md:text-lg mt-6">
                  Ralph Baxter talks about the importance of innovation and how it can make legal services better. He highlights the role of modern tools and technology in helping lawyers become better at what they do.
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
          <div className="flex items-center text-white w-fit mx-auto my-4">
            <div className="mr-5 font-bold text-xl">SPEAKERS</div>
            <Link to="/learn" className="bg-[#FF4B6C] text-lg px-8 py-2 rounded-full leading-relaxed">Learn more</Link>
          </div>
          <div
            className="relative"
          >
            <div className={`grid grid-cols-3 gap-2 mx-auto transition-opacity duration-500 ${(activeSlide === 0 || activeSlide === 1) ? 'opacity-100' : 'opacity-0'} absolute top-0`}>
              <img
                src={StephanieHamonChrisGrant}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718640127?h=2bbd968b86&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <img
                src={PaulHogg}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <img
                src={CatherineGoodman}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
            </div>
            <div className={`grid grid-cols-3 gap-2 mx-auto transition-opacity duration-500 ${activeSlide === 2 ? 'opacity-100' : 'opacity-0'}`}>
              <img
                src={LarsRasmussen}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <img
                src={DavidKerr}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <img
                src={JasonBarnwell}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
            </div>
            <div className={`grid grid-cols-3 gap-2 mx-auto transition-opacity duration-500 ${activeSlide === 3 ? 'opacity-100' : 'opacity-0'} absolute top-0`}>
              <img
                src={ChrisGrant}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <img
                src={RalphBaxter}
                className="flex-1 object-cover rounded rounded-lg cursor-pointer"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1'
                  })
                }}
              />
              <div />
            </div>
          </div>
        </div>
      </div>
      {isPlaying &&
          (
            <MobilePlayer
              onClose={() => setIsPlaying(false)}
              currentVideo={currentVideo}
            />
          )
      }
    </>
  )
}

const MasterClassNew = () => (
  <>
    <div className="hidden lg:block">
      <DesktopMasterClassNew />
    </div>
    <div className="lg:hidden">
      <MobileMasterClassNew />
    </div>
  </>
)

export default MasterClassNew
