import React from 'react'
import PropTypes from 'prop-types'

const TabItem = ({ tabContentId, children }) => {
  const handleClick = () => {
    const el = document.getElementById(tabContentId)
    const rect = el.getBoundingClientRect()
    const top = rect.top + window.pageYOffset - 300

    el && window.scrollTo({ top: top, behavior: 'smooth' })
  }
  return (
    <div onClick={handleClick} className="cursor-pointer">{children}</div>
  )
}

TabItem.propTypes = {
  tabContentId: PropTypes.string,
  children: PropTypes.element
}

export default TabItem
